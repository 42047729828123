var Main = (function() {

    var initLanguageSwitcher = function() {
        $('#language-switcher').on('change', function(){
            var url = $(this).find("option:selected").data('target');
            window.location.href = url;
        });
    };

    var hideMenuOn404 = function() {
        if ($('#404').length) {
            $('header nav.navbar').hide();
        }
    };

    return {
        init: function()
        {
            initLanguageSwitcher();
            hideMenuOn404();
        }
    };

})();
